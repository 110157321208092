import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clientPost } from '../../../utils';
import { URLS } from '../../../constants';
import Spinner from '../../../components/Spinner';
import './Onboarding.css';

const ContractPage = ({ setClient }) => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUnsignedContractUrl = async () => {
      try {
        const response = await clientPost(URLS.FETCH_HOST_AGREEMENT);
        const data = await response.json();
        setUrl(data.url);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching unsigned contract URL:', error);
        setLoading(false);
      }
    };

    fetchUnsignedContractUrl();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const response = await clientPost(URLS.CLIENT_SIGN_HOSTING);
      if (!response.ok) {
        console.error('Failed submitting client contract host agreement.');
        setSubmitting(false);
      } else {
        setClient({ status: 'APPOINTMENT', hostingAgreementSigned: true });
        navigate('/account');
      }
    } catch (error) {
      console.error('Failed submitting client contract host agreement.');
      setSubmitting(false);
    }
  };

  const handleCheckboxChange = (event) => {
    setAgreementChecked(event.target.checked);
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      {!loading && url && (
        <p className="text-lg text-gray-800 mb-6">
          Please read and sign your hosting contract agreement:
        </p>
      )}

      {loading ? (
        <Spinner />
      ) : (
        url && (
          <iframe
            className="w-full h-[600px] border-2 border-gray-300 rounded-lg"
            title="unsigned-hosting-agreement"
            src={url}
          />
        )
      )}

      {!loading && url && (
        <form className="mt-6 space-y-4" onSubmit={handleSubmit}>
          <label className="flex items-center space-x-2 text-sm text-gray-700">
            <input
              type="checkbox"
              name="agreement"
              onChange={handleCheckboxChange}
              className="text-blue-500 border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
            <span>I agree to the terms and conditions</span>
          </label>

          {!submitting ? (
            <button
              className={`w-full py-3 px-6 rounded-md text-white font-semibold focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                agreementChecked
                  ? 'bg-blue-600 hover:bg-blue-700'
                  : 'bg-gray-400 cursor-not-allowed'
              }`}
              type="submit"
              disabled={!agreementChecked}
            >
              Continue
            </button>
          ) : (
            <Spinner />
          )}
        </form>
      )}
    </div>
  );
};

export default ContractPage;
