import { useState } from 'react';
import { Link } from 'react-router-dom';
import { post } from '../../utils';
import { URLS } from '../../constants';
import SmallSpinner from '../../components/SmallSpinner';

const UserForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const response = await post(URLS.USER_FORGOT_PASSWORD, { email });
      if (errorMessage) {
        setErrorMessage('');
      }

      if (response.ok) {
        setMessage(
          'Your password reset link has been emailed to you. You have 15 minutes to change your password.',
        );
        setSubmitting(false);
        setSubmitted(true);
      } else {
        setErrorMessage(
          'Failed to send password reset link. Please try again.',
        );
        setSubmitting(false);
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
      setSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center bg-gray-50 p-6">
      <form
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md space-y-6"
        onSubmit={handleSubmit}
      >
        <h2 className="text-3xl font-semibold text-gray-800">
          Forgot Password
        </h2>

        {message ? (
          <p className="text-center text-sm text-gray-700">{message}</p>
        ) : (
          <>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Enter email:
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mt-2"
              />
            </div>

            <div>
              {submitting ? (
                <div className="flex justify-center">
                  <SmallSpinner />
                </div>
              ) : (
                <button
                  type="submit"
                  disabled={submitted || !email}
                  className="w-full py-3 bg-blue-600 text-white font-semibold rounded-md disabled:bg-gray-300 hover:bg-blue-700 focus:outline-none mt-4"
                >
                  Submit
                </button>
              )}
              {errorMessage && (
                <p className="text-center text-sm text-red-500 mt-4">
                  {errorMessage}
                </p>
              )}
            </div>
          </>
        )}

        <Link
          to="/"
          className="block text-center text-blue-600 hover:text-blue-800 text-sm mt-4"
        >
          Back to home.
        </Link>
      </form>
    </div>
  );
};

export default UserForgotPassword;
