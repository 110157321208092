import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import NotFound from '../../components/NotFound';
import { post } from '../../utils';
import { URLS } from '../../constants';
import SmallSpinner from '../../components/SmallSpinner';

const MagicInfraSignup = ({ login }) => {
  const { magic_code } = useParams();
  const navigate = useNavigate();
  const [validMagicCode, setValidMagicCode] = useState(false);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [idToUpdate, setIdToUpdate] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [passwordError, setPasswordError] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    passLength: false,
    passwordMatch: false,
  });
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const validateMagicCode = async () => {
      try {
        const response = await post(URLS.VALIDATE_INFRA_MAGIC_CODE, {
          magic_code,
        });
        if (!response.ok) {
          setError('Magic code validation failed.');
          setLoading(false);
        } else {
          const data = await response.json();
          setRole(data.role);
          setEmail(data.email);
          setIdToUpdate(data.id);
          setValidMagicCode(true);
          setLoading(false);
        }
      } catch (error) {
        setError('Invalid magic code.');
        setLoading(false);
      }
    };

    validateMagicCode();
  }, [magic_code]);

  useEffect(() => {
    const errors = {
      lowercase: !/(?=.*[a-z])/.test(password),
      uppercase: !/(?=.*[A-Z])/.test(password),
      number: !/(?=.*[0-9])/.test(password),
      special: !/(?=.*[!@#$%^&*()_+{}[\]:;<>,./?|-])/.test(password),
      passLength: password.length >= 11,
      passwordMatch:
        password === repeatPassword && (password || repeatPassword),
    };
    setPasswordError(errors);
  }, [password, repeatPassword]);

  const handleShowHideButton = (event) => {
    event.preventDefault();
    setHiddenPassword(!hiddenPassword);
  };

  const handleSignup = async () => {
    setSubmitting(true);
    try {
      const response = await post(URLS.UPDATE_INFRA_USER, {
        email,
        password,
        role,
        idToUpdate,
      });
      if (!response.ok) {
        setError('User signup failed.');
        setSubmitting(false);
      } else {
        const data = await response.json();
        Cookies.set('token', data.token);
        Cookies.set('email', email);
        const user = {
          email,
          role,
        };
        login(user);
        navigate('/admin/dashboard');
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError(error.message);
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSignup();
    }
  };

  return (
    <div>
      {validMagicCode ? (
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
          <p className="text-lg text-gray-700 mb-6">
            Hello {email}
            <br />
            Please add a password to finish setting up your account.
          </p>

          <div className="relative mb-6">
            <input
              type={hiddenPassword ? 'password' : 'text'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              maxLength={40}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              tabIndex="-1"
              className="absolute top-1/2 right-4 transform -translate-y-1/2 text-xs text-black bg-gray-200 border border-gray-300 rounded-lg px-3 py-1 hover:bg-gray-300 focus:outline-none"
              onClick={handleShowHideButton}
            >
              {hiddenPassword ? 'Show' : 'Hide'}
            </button>
          </div>

          <input
            type="password"
            placeholder="Repeat Password"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            maxLength={40}
            onKeyDown={handleKeyDown}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />

          {submitting ? (
            <SmallSpinner />
          ) : (
            <button
              disabled={
                passwordError.lowercase ||
                passwordError.uppercase ||
                passwordError.number ||
                passwordError.special ||
                !passwordError.passLength ||
                !passwordError.passwordMatch
              }
              onClick={handleSignup}
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              Sign Up
            </button>
          )}

          <div className="mt-4 text-sm text-gray-600">
            <div
              className={`mb-2 ${passwordError.lowercase ? 'block' : 'hidden'}`}
            >
              Password must contain at least one lowercase letter.
            </div>
            <div
              className={`mb-2 ${passwordError.uppercase ? 'block' : 'hidden'}`}
            >
              Password must contain at least one uppercase letter.
            </div>
            <div
              className={`mb-2 ${passwordError.number ? 'block' : 'hidden'}`}
            >
              Password must contain at least one number.
            </div>
            <div
              className={`mb-2 ${passwordError.special ? 'block' : 'hidden'}`}
            >
              Password must contain at least one special character.
            </div>
            <div
              className={`mb-2 ${!passwordError.passLength ? 'block' : 'hidden'}`}
            >
              Password must be at least 11 characters long.
            </div>
            <div
              className={`mb-2 ${!passwordError.passwordMatch ? 'block' : 'hidden'}`}
            >
              Passwords must match.
            </div>
          </div>

          {error && <p className="text-red-500 text-sm mt-4">{error}</p>}
        </div>
      ) : loading ? (
        <SmallSpinner />
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default MagicInfraSignup;
