import getFormattedDate from '../pages/admin/utils';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';

const ActiveClientSiteDetails = ({
  site,
  uploading,
  file,
  handleFileUpload,
  handleFileChange,
  selectedSiteIdContract,
  selectedAppointment,
  handleScheduleAppointment,
  scheduling,
  handleSelectEvent,
  eventStyleGetter,
  formatEvents,
}) => {
  const localizer = momentLocalizer(moment);

  return (
    <div className="site-details bg-white rounded-lg shadow-lg p-6 space-y-6">
      <h4 className="text-xl font-semibold text-gray-800">Site Details</h4>
      <div className="text-gray-600">
        <p>
          <strong>Site Address:</strong> {site.address}
        </p>
        <p>
          <strong>Status:</strong> {site.status}
        </p>
        <p>
          <strong>Installer:</strong>{' '}
          {!site.installer.email.length
            ? 'Waiting for OPS to assign.'
            : site.installer.email}
        </p>
        <p>
          <strong>Daily Patrons:</strong> {site.dailyPatrons}
        </p>
        <p>
          <strong>Square Footage:</strong> {site.squareFootage}
        </p>
        <p>
          <strong>Site used during onboarding:</strong> {String(site.default)}
        </p>
      </div>

      <hr className="border-t-2 border-gray-200" />

      <h4 className="text-xl font-semibold text-gray-800">
        Hosting Agreements
      </h4>
      <ul className="space-y-4">
        {site.hostingAgreements.length > 0 ? (
          site.hostingAgreements.map((agreement) => (
            <div
              key={agreement.id}
              className="active-client-details-box bg-gray-100 rounded-lg p-4"
            >
              <p>
                <strong>Status:</strong> {agreement.status}
              </p>
              <p>
                <strong>Url:</strong>{' '}
                <a
                  href={agreement.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View Document
                </a>
              </p>
              <p>
                <strong>Created:</strong> {getFormattedDate(agreement.created)}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No hosting agreements yet.</p>
        )}
      </ul>

      <hr className="border-t-2 border-gray-200" />

      <h4 className="text-xl font-semibold text-gray-800">Appointments</h4>
      <ul className="space-y-4">
        {site.appointments.length > 0 ? (
          site.appointments.map((appointment) => (
            <div
              key={appointment.id}
              className="active-client-details-box bg-gray-100 rounded-lg p-4"
            >
              <p>
                <strong>Scheduled Date:</strong>{' '}
                {getFormattedDate(appointment.scheduledDate)}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No appointments yet.</p>
        )}
      </ul>

      <hr className="border-t-2 border-gray-200" />

      <h4 className="text-xl font-semibold text-gray-800">Upload Contract</h4>
      <form
        onSubmit={(e) => handleFileUpload(e, site.id)}
        className="space-y-4"
      >
        <div className="flex flex-col space-y-2">
          <input
            type="file"
            onChange={(e) => handleFileChange(e, site.id)}
            accept="application/pdf"
            className="border border-gray-300 p-2 rounded-md"
          />
          <button
            type="submit"
            disabled={uploading || !file || selectedSiteIdContract !== site.id}
            className="bg-blue-600 text-white rounded-md p-2 hover:bg-blue-700 disabled:opacity-50"
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      </form>

      <hr className="border-t-2 border-gray-200" />

      {site.installer.email.length !== 0 && (
        <>
          <h4 className="text-xl font-semibold text-gray-800">
            Create Appointment with {site.installer?.email}
          </h4>
          {selectedAppointment && (
            <div className="space-y-4">
              <p>
                <strong>Selected Time:</strong>{' '}
                {moment(selectedAppointment.start).format('LLLL')} -{' '}
                {moment(selectedAppointment.end).format('LLLL')}
              </p>
              <button
                disabled={scheduling}
                onClick={() => handleScheduleAppointment(site.id)}
                className="bg-green-600 text-white rounded-md p-2 hover:bg-green-700 disabled:opacity-50"
              >
                Schedule
              </button>
              <hr className="border-t-2 border-gray-200" />
            </div>
          )}
          <div className="calendar-wrapper">
            <Calendar
              localizer={localizer}
              defaultView="week"
              views={['week', 'day']}
              events={formatEvents(site.installer?.availability)}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              eventPropGetter={eventStyleGetter}
              onSelectEvent={(e) => handleSelectEvent(e, site.id)}
              selectable
              onSelectSlot={() => {}}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ActiveClientSiteDetails;
