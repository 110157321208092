import { useState } from 'react';
import { clientPost } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../constants';
import { useStripeConnect } from './useStripeConnect';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import './Onboarding.css';

const PayoutPage = ({ client, setClient }) => {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();
  const stripeConnectInstance = useStripeConnect(connectedAccountId);

  const navigate = useNavigate();

  const handleSkipSetup = async () => {
    try {
      const response = await clientPost(URLS.CLIENT_SKIP_PAYOUT);
      if (response.ok) {
        setClient({ status: 'HOME' });
        navigate('/account');
      } else {
        console.error('Failed to skip payout.');
      }
    } catch (error) {
      console.error('Error skipping payout:', error);
    }
  };

  const createAccount = async () => {
    try {
      setAccountCreatePending(true);
      setError(false);
      const response = await clientPost(URLS.STRIPE_ACCOUNT);
      const json = await response.json();
      setAccountCreatePending(false);
      const { account, error } = json;
      if (account) {
        setConnectedAccountId(account);
      }
      if (error) {
        setError(true);
      }
    } catch (error) {
      setAccountCreatePending(false);
      setError(true);
    }
  };

  const exitOnboarding = async () => {
    try {
      setOnboardingExited(true);
      const response = await clientPost(URLS.CLIENT_FINISH_PAYOUT, {
        connectedAccountId,
      });
      if (response.ok) {
        setClient({ status: 'HOME' });
        navigate('/account');
      } else {
        console.error('Failed to finish payout.');
      }
    } catch (error) {
      console.error('Error exitting payout:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg space-y-6">
      <div className="bg-blue-100 p-4 rounded-lg text-center">
        <h2 className="text-2xl font-semibold text-blue-700">
          Select a Payout Method:
        </h2>
      </div>
      <div>
        {connectedAccountId && !stripeConnectInstance ? (
          <h2 className="text-xl font-semibold text-gray-800">
            Add information to start accepting money
          </h2>
        ) : (
          !connectedAccountId && (
            <p className="text-gray-700">
              Rhythm Wireless: Join our network of hosts and earn money!
            </p>
          )
        )}

        {!accountCreatePending && !connectedAccountId && (
          <div className="mt-4">
            <button
              className="w-full py-3 px-6 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-green-500"
              onClick={createAccount}
            >
              Connect with Stripe
            </button>
          </div>
        )}

        {stripeConnectInstance && (
          <ConnectComponentsProvider
            className="stripe-provider"
            connectInstance={stripeConnectInstance}
          >
            <ConnectAccountOnboarding
              className="stripe-inner-provider"
              onExit={() => exitOnboarding()}
            />
          </ConnectComponentsProvider>
        )}

        {error && <p className="text-red-600 mt-2">Something went wrong!</p>}

        {(connectedAccountId || accountCreatePending || onboardingExited) && (
          <div className="mt-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 rounded-lg">
            {accountCreatePending && (
              <p className="text-yellow-700">Creating a connected account...</p>
            )}
          </div>
        )}

        <button
          className="w-full py-3 px-6 mt-4 bg-gray-400 hover:bg-gray-500 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
          type="button"
          onClick={handleSkipSetup}
        >
          Set up later
        </button>
      </div>
    </div>
  );
};

export default PayoutPage;
