import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { adminPost, adminUpload } from '../../utils';
import { URLS } from '../../constants';
import SmallSpinner from '../../components/SmallSpinner';
import moment from 'moment';
import ActiveClientSiteDetails from '../../components/ActiveClientSiteDetails';

const ActiveClientsDetails = () => {
  const { userid } = useParams();
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedSiteIdContract, setSelectedSiteIdContract] = useState(null);
  const [selectedSiteIdAppointment, setSelectedSiteIdAppointment] =
    useState(null);
  const [willSkipContract, setWillSkipContract] = useState(false);
  const [willSkipAppointment, setWillSkipAppointment] = useState(false);
  const [canSkipContract, setCanSkipContract] = useState('loading');
  const [canSkipAppointment, setCanSkipAppointment] = useState('loading');
  const [saving, setSaving] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const [newSiteData, setNewSiteData] = useState({
    address: '',
    dailyPatrons: '',
    squareFootage: '',
  });
  const [addingSite, setAddingSite] = useState(false);
  const [inviteLink, setInviteLink] = useState('');

  useEffect(() => {
    const fetchOnboardingSettings = async () => {
      const response = await adminPost(URLS.GET_ACTIVE_CLIENT_DETAILS, {
        userid,
      });
      if (response.ok) {
        const data = await response.json();
        setClientDetails(data);
        const defaultSite = data.sites.find((site) => site.default);
        setCanSkipContract(defaultSite?.hostingAgreements.length > 0);
        setCanSkipAppointment(true);
        setWillSkipContract(data.willSkipContract);
        setWillSkipAppointment(data.willSkipAppointment);
      } else {
        console.error('Failed to fetch onboarding settings');
      }
      setLoadingDetails(false);
    };
    fetchOnboardingSettings();
  }, [userid]);

  const handleSaveWillSkip = async () => {
    setSaving(true);
    try {
      const response = await adminPost(URLS.SAVE_ONBOARDING_SETTINGS, {
        userid,
        willSkipContract,
        willSkipAppointment,
      });

      if (!response.ok) {
        throw new Error('Failed to save settings');
      }
      window.location.reload();
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleInvite = async () => {
    setInviting(true);
    const response = await adminPost(URLS.INVITE_CLIENT_TO_MAKE_ACCOUNT, {
      userid,
    });
    if (!response.ok) {
      console.error('Failed to invite client');
      return;
    }
    const data = await response.json();
    setInviteLink(data.link);
    setInviting(false);
  };

  const handleFileChange = (e, siteId) => {
    setFile(e.target.files[0]);
    setSelectedSiteIdContract(siteId);
  };

  const handleFileUpload = async (e, siteId) => {
    e.preventDefault();
    if (!file || selectedSiteIdContract !== siteId) return;
    setUploading(true);

    const result = await adminUpload(URLS.UPLOAD_CONTRACT, {
      file,
      userid,
      siteId,
    });

    if (!result.success) {
      console.error(result.message);
      setUploading(false);
      return;
    }

    window.location.reload();
    setUploading(false);
  };

  const handleScheduleAppointment = async (siteId) => {
    if (!selectedAppointment || selectedSiteIdAppointment !== siteId) {
      return;
    }
    setScheduling(true);
    try {
      const response = await adminPost(URLS.SCHEDULE_APPOINTMENT, {
        userid,
        siteId,
        appointment: selectedAppointment.start,
      });

      if (!response.ok) {
        throw new Error('Failed to save settings');
      }
      window.location.reload();
    } catch (error) {
      console.error('Error scheduling appointment:', error);
    } finally {
      setScheduling(false);
    }
  };

  const handleSelectEvent = (event, siteId) => {
    // Ensure this event is not 'Busy' and can initiate a 3-hour block
    if (event.title !== 'Busy' && checkAvailability(event.start)) {
      setSelectedAppointment({
        start: event.start,
        end: moment(event.start).add(3, 'hours').toDate(),
      });
      setSelectedSiteIdAppointment(siteId);
    } else {
      setSelectedAppointment(null);
    }
  };

  const checkAvailability = (start) => {
    for (let i = 0; i < 6; i++) {
      // Check for 3 hours of availability
      const currentSlotStart = moment(start).add(i * 30, 'minutes');
      if (!isSelectable(currentSlotStart)) return false;
    }
    return true;
  };

  const formatEvents = (availability) => {
    return availability
      .filter((event) => moment(event.start).isAfter(moment())) // Filter out past events
      .map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        title: event.title,
      }));
  };

  const eventStyleGetter = (event) => {
    const eventStart = moment(event.start);
    const eventEnd = moment(event.end);
    let backgroundColor = '#d3d3d3'; // Default to grey for unselectable

    if (event.title === 'Busy') {
      backgroundColor = '#ff6347'; // Red for busy
    } else if (
      selectedAppointment &&
      eventStart.isSameOrAfter(selectedAppointment.start) &&
      eventEnd.isSameOrBefore(moment(selectedAppointment.start).add(3, 'hours'))
    ) {
      backgroundColor = '#32cd32'; // Green for selected valid range
    } else if (canInitiateValidBlock(event.start)) {
      backgroundColor = '#3174ad'; // Blue for selectable
    }
    return {
      style: {
        backgroundColor,
        color: 'white',
      },
    };
  };

  const canInitiateValidBlock = (start) => {
    // Check availability for the next 3 hours from 'start'
    for (let i = 0; i < 6; i++) {
      const currentSlotStart = moment(start).add(i * 30, 'minutes');
      if (!isSelectable(currentSlotStart)) {
        return false;
      }
    }
    return true;
  };

  const isSelectable = (start) => {
    const slotEnd = moment(start).add(30, 'minutes');
    const slotEvent = clientDetails.sites[0].installer?.availability.find(
      (e) =>
        moment(e.start).isSame(start) &&
        moment(e.end).isSame(slotEnd) &&
        e.title !== 'Busy',
    );
    return !!slotEvent;
  };

  const openAddSiteModal = () => {
    setShowAddSiteModal(true);
  };

  const closeAddSiteModal = () => {
    setShowAddSiteModal(false);
    setNewSiteData({
      address: '',
      dailyPatrons: '',
      squareFootage: '',
    });
  };

  const handleNewSiteChange = (e) => {
    const { name, value } = e.target;
    setNewSiteData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddSiteSubmit = async () => {
    setAddingSite(true);
    const siteDetails = {
      ...newSiteData,
      userid,
    };

    const response = await adminPost(URLS.ADD_SITE, siteDetails);
    if (response.ok) {
      window.location.reload();
    } else {
      console.error('Failed to add site');
      setAddingSite(false);
    }
    closeAddSiteModal();
  };

  return (
    <div className="p-8 bg-white rounded-lg shadow-lg space-y-6">
      <Link
        to="/admin/active-clients"
        className="text-blue-500 hover:text-blue-700 transition-colors duration-300 text-lg font-semibold"
      >
        Back To Active Clients
      </Link>

      <h2 className="text-3xl font-bold text-gray-800">
        Active Client Details
      </h2>
      <hr className="border-t-2 border-gray-300" />

      {loadingDetails ? (
        <div className="flex justify-center items-center">
          <SmallSpinner />
        </div>
      ) : (
        <>
          <div>
            <h3 className="text-xl font-semibold text-gray-700">
              Account Details
            </h3>
            <div className="space-y-2">
              <p className="text-gray-600">
                <span className="font-medium">Business Name:</span>{' '}
                {clientDetails?.businessName}
              </p>
              <p className="text-gray-600">
                <span className="font-medium">Email:</span>{' '}
                {clientDetails?.email}
              </p>
              <p className="text-gray-600">
                <span className="font-medium">Full Name:</span>{' '}
                {clientDetails?.fullName}
              </p>
              <p className="text-gray-600">
                <span className="font-medium">Phone:</span>{' '}
                {clientDetails?.phoneNumber}
              </p>
              <p className="text-gray-600">
                <span className="font-medium">Sales Rep:</span>{' '}
                {clientDetails?.salesRep}
              </p>
              <p className="text-gray-600">
                <span className="font-medium">Ops Rep:</span>{' '}
                {clientDetails?.opsRep}
              </p>
            </div>
          </div>

          <hr className="border-t-2 border-gray-300" />

          <div>
            <h3 className="text-xl font-semibold text-gray-700">
              Onboarding Settings
            </h3>
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={willSkipContract}
                  onChange={(e) => setWillSkipContract(e.target.checked)}
                  disabled={canSkipContract === 'loading' || !canSkipContract}
                  className="text-blue-600"
                />
                <label className="text-gray-600">Skip Contract</label>
              </div>
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={willSkipAppointment}
                  onChange={(e) => setWillSkipAppointment(e.target.checked)}
                  disabled={
                    canSkipAppointment === 'loading' || !canSkipAppointment
                  }
                  className="text-blue-600"
                />
                <label className="text-gray-600">Skip Appointment</label>
              </div>

              <button
                onClick={handleSaveWillSkip}
                disabled={
                  saving ||
                  canSkipContract === 'loading' ||
                  canSkipAppointment === 'loading'
                }
                className="bg-blue-600 text-white py-2 px-4 rounded-lg disabled:opacity-50 hover:bg-blue-700 transition duration-200"
              >
                {saving ? 'Saving...' : 'Save'}
              </button>
            </div>

            <div className="mt-6">
              <h4 className="text-lg font-medium text-gray-700">
                Invite Client to Start Onboarding
              </h4>
              <button
                onClick={handleInvite}
                disabled={
                  inviting ||
                  canSkipContract === 'loading' ||
                  canSkipAppointment === 'loading'
                }
                className="bg-green-600 text-white py-2 px-4 rounded-lg disabled:opacity-50 hover:bg-green-700 transition duration-200"
              >
                {inviting ? 'Inviting...' : 'Invite'}
              </button>
              <p className="text-gray-600 mt-2">Link: {inviteLink}</p>
            </div>
          </div>

          <hr className="border-t-2 border-gray-300" />

          <div>
            <h3 className="text-xl font-semibold text-gray-700">Sites</h3>
            <button
              onClick={openAddSiteModal}
              className="bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-200"
            >
              Add Site
            </button>

            {showAddSiteModal && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                  <span
                    className="absolute top-2 right-2 text-gray-600 cursor-pointer"
                    onClick={closeAddSiteModal}
                  >
                    &times;
                  </span>
                  <h4 className="text-2xl font-semibold text-gray-800">
                    Add New Site
                  </h4>
                  <div className="space-y-4 mt-4">
                    <label className="block text-gray-600">Address</label>
                    <input
                      type="text"
                      name="address"
                      value={newSiteData.address}
                      onChange={handleNewSiteChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />

                    <label className="block text-gray-600">Daily Patrons</label>
                    <input
                      type="text"
                      name="dailyPatrons"
                      value={newSiteData.dailyPatrons}
                      onChange={handleNewSiteChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />

                    <label className="block text-gray-600">
                      Square Footage
                    </label>
                    <input
                      type="text"
                      name="squareFootage"
                      value={newSiteData.squareFootage}
                      onChange={handleNewSiteChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />

                    {addingSite ? (
                      <SmallSpinner />
                    ) : (
                      <button
                        onClick={handleAddSiteSubmit}
                        disabled={
                          newSiteData.squareFootage.length === 0 ||
                          newSiteData.dailyPatrons.length === 0 ||
                          newSiteData.address.length === 0
                        }
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg disabled:opacity-50 hover:bg-blue-700 transition duration-200"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}

            {clientDetails?.sites.map((site) => (
              <ActiveClientSiteDetails
                key={site.id}
                site={site}
                uploading={uploading}
                file={file}
                handleFileChange={handleFileChange}
                handleFileUpload={handleFileUpload}
                handleSelectEvent={handleSelectEvent}
                eventStyleGetter={eventStyleGetter}
                handleScheduleAppointment={handleScheduleAppointment}
                selectedSiteIdContract={selectedSiteIdContract}
                selectedAppointment={selectedAppointment}
                scheduling={scheduling}
                formatEvents={formatEvents}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ActiveClientsDetails;
