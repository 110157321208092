import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { adminPost } from '../../utils';
import { URLS } from '../../constants';

import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);

  const token = Cookies.get('token');
  const username = Cookies.get('username');
  const email = Cookies.get('email');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await adminPost(URLS.GET_ADMIN_USERS);
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [token, username, email]);

  return (
    <div className="p-6 bg-white shadow-lg rounded-lg">
      <Link
        to="/admin/dashboard"
        className="text-blue-600 hover:text-blue-800 font-medium mb-6 inline-block"
      >
        Back To Dashboard
      </Link>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Internal Users
      </h2>

      <Link
        to="/admin/invite-user"
        className="text-white bg-blue-600 hover:bg-blue-700 font-medium py-2 px-4 rounded-lg mb-6 inline-block"
      >
        Invite User
      </Link>

      <hr className="border-t-2 border-gray-300 mb-6" />

      {usersLoading ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : !users.length ? (
        <p className="text-center text-gray-500">No users.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg border border-gray-300">
            <thead className="bg-gray-100 text-left">
              <tr>
                <th className="px-4 py-2 font-medium text-gray-600">Email</th>
                <th className="px-4 py-2 font-medium text-gray-600">Role</th>
                <th className="px-4 py-2 font-medium text-gray-600">Created</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.email} className="border-t">
                  <td className="px-4 py-2 text-gray-800">{user.email}</td>
                  <td className="px-4 py-2 text-gray-800">{user.role}</td>
                  <td className="px-4 py-2 text-gray-800">
                    {getFormattedDate(user.created)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminUsers;
