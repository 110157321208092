import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { post } from '../utils';
import { URLS } from '../constants';
import './Modal.css';

import { FaEye, FaEyeSlash } from 'react-icons/fa';

import business_image_url from '../images/Group149.png';
import household_image_url from '../images/Group148.png';
import SmallSpinner from './SmallSpinner';

const Modal = ({ onClose, type, loginClient }) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [loginDisabled, setLoginDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleBodyOverflow = (isOpen) => {
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    };

    handleBodyOverflow(true);

    return () => {
      handleBodyOverflow(false);
    };
  }, []);

  const handleCloseAndNavigate = () => {
    onClose();
  };

  const handleClientLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await post(URLS.CLIENT_LOGIN, { email, password });
      if (response.ok) {
        const data = await response.json();
        Cookies.set('clientToken', data.clientToken);
        Cookies.set('clientEmail', data.clientEmail);
        loginClient(data);
        onClose();
        navigate('/account');
      } else {
        setLoginError('Invalid email or password.');
        setLoading(false);
      }
    } catch (error) {
      setLoginError('Our servers are down, please try again later.');
      setLoading(false);
    }
    const newLoginAttempts = 1 + loginAttempts;
    setLoginAttempts(newLoginAttempts);
    if (newLoginAttempts > 4) {
      setLoginDisabled(true);
    }
  };

  const handleModalClick = (event) => {
    if (
      event.target.classList.contains('modal-content') ||
      event.target.classList.contains('modal-title') ||
      event.target.classList.contains('modal-options')
    ) {
      event.stopPropagation();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (type === 'signup') {
    return (
      <div className="modal" onClick={onClose}>
        <div className="modal-content" onClick={handleModalClick}>
          <span className="modal-close" onClick={onClose}>
            &times;
          </span>
          <h2 className="modal-title">Are you a business or household?</h2>
          <div className="modal-options">
            <Link to="/business" onClick={handleCloseAndNavigate}>
              <img
                src={business_image_url}
                alt="Business"
                className="modal-img-left"
              />
            </Link>
            <Link to="/household" onClick={handleCloseAndNavigate}>
              <img
                src={household_image_url}
                alt="Household"
                className="modal-img-right"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="modal">
        <div className="modal-content" onClick={handleModalClick}>
          <span className="modal-close" onClick={onClose}>
            &times;
          </span>
          <h2 className="modal-title">Login</h2>
          <form className="modal-login-form" onSubmit={handleClientLogin}>
            <input
              className="modal-form-input"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="username"
            />
            <div className="password-container">
              <input
                className="modal-form-input"
                type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password'
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            {!loading ? (
              <>
                <button
                  type="submit"
                  className="modal-button-login"
                  disabled={
                    !email ||
                    !password ||
                    password.length < 4 ||
                    email.length < 8 ||
                    loginDisabled
                  }
                >
                  Login
                </button>
                {loginError && (
                  <p className="login-modal-error-message">{loginError}</p>
                )}
              </>
            ) : (
              <SmallSpinner />
            )}
          </form>
          <div className="modal-footer">
            <Link
              to="/forgot-password"
              className="forgot-password-link"
              onClick={() => {
                onClose();
              }}
            >
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default Modal;
