import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminPost } from '../../utils';
import { URLS } from '../../constants';
import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';

const ClientDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setDocumentsLoading(true);
        const response = await adminPost(URLS.GET_CLIENT_DOCUMENTS, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch client documents');
          return;
        }
        const data = await response.json();
        setDocuments(data.documents);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching client documents:', error);
      }
      setDocumentsLoading(false);
    };
    fetchDocuments();
  }, [page]);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`px-3 py-1 rounded-lg transition-all border ${
            i === page
              ? 'bg-blue-500 text-white border-blue-500'
              : 'bg-white text-gray-600 border-gray-300 hover:bg-gray-100 hover:text-blue-500'
          }`}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  return (
    <div className="admin-dash-container p-6 bg-white rounded-lg shadow-md">
      <Link
        to="/admin/dashboard"
        className="text-blue-600 hover:text-blue-800 font-medium text-lg"
      >
        Back To Dashboard
      </Link>
      <h2 className="text-2xl font-semibold mt-4 mb-2">Client Documents</h2>
      <hr className="border-gray-300 my-4" />

      {documentsLoading ? (
        <Spinner />
      ) : !documents.length ? (
        <p className="text-center text-gray-600">No client documents.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto text-sm text-left text-gray-600">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Client Email
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Business Name
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">Address</th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Document Status
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Document Link
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Sales Rep
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">Ops Rep</th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Installer
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Created Date
                </th>
              </tr>
            </thead>
            <tbody>
              {documents.map((document) => (
                <tr key={document.id} className="border-b hover:bg-gray-50">
                  <td className="py-3 px-4">{document.clientEmail}</td>
                  <td className="py-3 px-4">{document.businessName}</td>
                  <td className="py-3 px-4">{document.address}</td>
                  <td className="py-3 px-4">{document.status}</td>
                  <td className="py-3 px-4">
                    <a
                      href={document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-800"
                    >
                      Link
                    </a>
                  </td>
                  <td className="py-3 px-4">{document.salesRep}</td>
                  <td className="py-3 px-4">{document.opsUser}</td>
                  <td className="py-3 px-4">{document.installer}</td>
                  <td className="py-3 px-4">
                    {getFormattedDate(document.created)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={prevPage}
          disabled={page === 1}
          className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          Prev
        </button>

        <div className="flex items-center space-x-2">{renderPageNumbers()}</div>

        <button
          onClick={nextPage}
          disabled={page === totalPages}
          className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ClientDocuments;
