import { useEffect, useRef, useState } from 'react';
import Modal from '../../components/Modal';
import './Intro.css';

const Intro = ({
  signupModalOpen,
  toggleSignupModal,
  loginModalOpen,
  toggleLoginModal,
  updateClient,
}) => {
  const numCircles = 100;
  const circleIndices = Array.from({ length: numCircles }, (_, i) => i + 1);

  const [isVisible, setIsVisible] = useState(true);
  const firstSectionRef = useRef(null);
  const secondSectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 100;
      const currentScrollY = window.scrollY;

      if (currentScrollY > threshold && isVisible) {
        setIsVisible(false);
      } else if (currentScrollY <= threshold && !isVisible) {
        setIsVisible(true);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  const handleFirstScroll = () => {
    const currentSectionHeight =
      firstSectionRef.current.getBoundingClientRect().height;
    const offset = currentSectionHeight;
    window.scrollBy({ top: offset, behavior: 'smooth' });
  };

  return (
    <>
      <section ref={firstSectionRef} className="relative z-0">
        <div className="green-background h-[750px] w-full bg-[#17ba17] z-0">
          <div className="home-page-content absolute top-[17%] left-[5%] text-left text-black z-10">
            <h1 className="home-page-title text-4xl font-semibold tracking-wide">
              We are not your typical <br />
              internet company.
            </h1>
            <h2 className="home-page-subtitle text-lg font-medium tracking-wide mt-8 mb-6">
              We pay you to plug in a hotspot and earn you money <br />
              for your unused internet!
            </h2>
            <button
              className="sign-up-button w-32 bg-white text-black border border-white rounded-md py-2 px-4 text-lg cursor-pointer z-0"
              onClick={toggleSignupModal}
            >
              Signup
            </button>
            <button
              className="log-in-button w-32 bg-[#19ba17] text-black rounded-md py-2 px-4 text-lg cursor-pointer z-0 ml-10"
              onClick={toggleLoginModal}
            >
              Login
            </button>
          </div>
          {signupModalOpen && (
            <Modal
              onClose={toggleSignupModal}
              type="signup"
              setClient={updateClient}
            />
          )}
          {loginModalOpen && (
            <Modal
              onClose={toggleLoginModal}
              type="login"
              setClient={updateClient}
            />
          )}
        </div>
        <div className="circles-container absolute top-0 right-0 h-full w-full overflow-hidden z-0">
          {circleIndices.map((index) => (
            <div
              key={index}
              className={`circle circle-${index} top-1/2 right-0 border-8 border-[#1daf1dab] rounded-full transform translate-x-[50%] -translate-y-1/2`}
              style={{
                width: `${-50 + 100 * index}px`,
                height: `${-50 + 100 * index}px`,
              }}
            ></div>
          ))}
        </div>
        <div
          className={`scroll-down-arrow z-10 absolute top-[75%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full w-12 h-12 flex items-center justify-center text-white bg-[#118311] opacity-30 cursor-pointer transition-opacity duration-500 ease-in-out ${isVisible ? 'opacity-50' : 'opacity-0'}`}
          onClick={handleFirstScroll}
        >
          V
        </div>
      </section>

      <section ref={secondSectionRef} />
    </>
  );
};

export default Intro;
