import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import NotFound from '../../components/NotFound';
import { post } from '../../utils';
import { URLS } from '../../constants';
import SmallSpinner from '../../components/SmallSpinner';

const MagicInfraSignupForgot = ({ login }) => {
  const { magic_code } = useParams();
  const navigate = useNavigate();
  const [validMagicCode, setValidMagicCode] = useState(false);
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [idToUpdate, setIdToUpdate] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [passwordError, setPasswordError] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    passLength: false,
    passwordMatch: false,
  });
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const validateMagicCode = async () => {
      try {
        const response = await post(URLS.VALIDATE_INFRA_MAGIC_CODE_FORGOT, {
          magic_code,
        });
        if (!response.ok) {
          setError('Magic code validation failed.');
          setLoading(false);
        } else {
          const data = await response.json();
          setRole(data.role);
          setEmail(data.email);
          setIdToUpdate(data.id);
          setValidMagicCode(true);
          setLoading(false);
        }
      } catch (error) {
        setError('Invalid magic code.');
        setLoading(false);
      }
    };

    validateMagicCode();
  }, [magic_code]);

  useEffect(() => {
    const errors = {
      lowercase: !/(?=.*[a-z])/.test(password),
      uppercase: !/(?=.*[A-Z])/.test(password),
      number: !/(?=.*[0-9])/.test(password),
      special: !/(?=.*[!@#$%^&*()_+{}[\]:;<>,./?|-])/.test(password),
      passLength: password.length >= 11,
      passwordMatch:
        password === repeatPassword && (password || repeatPassword),
    };
    setPasswordError(errors);
  }, [password, repeatPassword]);

  const handleShowHideButton = (event) => {
    event.preventDefault();
    setHiddenPassword(!hiddenPassword);
  };

  const handleSignup = async () => {
    setSubmitting(true);
    try {
      const response = await post(URLS.UPDATE_INFRA_USER, {
        email,
        password,
        role,
        idToUpdate,
      });
      if (!response.ok) {
        setError('User signup failed.');
        setSubmitting(false);
      } else {
        const data = await response.json();
        Cookies.set('token', data.token);
        Cookies.set('email', email);
        const user = {
          email,
          role,
        };
        login(user);
        navigate('/admin/dashboard');
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError(error.message);
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSignup();
    }
  };

  return (
    <div>
      {validMagicCode ? (
        <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg">
          <p className="text-lg">
            Hello {email}
            <br /> Please create a new password to gain access to your account.
          </p>

          {/* Password Input */}
          <div className="relative">
            <input
              type={hiddenPassword ? 'password' : 'text'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              maxLength={40}
              className="w-full my-2 p-2 border rounded-lg focus:outline-none"
            />
            <button
              tabIndex="-1"
              onClick={handleShowHideButton}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs text-black bg-lightgray border border-gray-300 p-1 rounded-full cursor-pointer"
            >
              {hiddenPassword ? 'Show' : 'Hide'}
            </button>
          </div>

          {/* Repeat Password Input */}
          <input
            type="password"
            placeholder="Repeat Password"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            maxLength={40}
            onKeyDown={handleKeyDown}
            className="w-full my-2 p-2 border rounded-lg focus:outline-none"
          />

          {/* Submit Button */}
          {submitting ? (
            <SmallSpinner />
          ) : (
            <button
              onClick={handleSignup}
              disabled={
                passwordError.lowercase ||
                passwordError.uppercase ||
                passwordError.number ||
                passwordError.special ||
                !passwordError.passLength ||
                !passwordError.passwordMatch
              }
              className="w-full p-2 my-4 bg-blue-500 text-white rounded-lg disabled:bg-gray-300"
            >
              Reset Password
            </button>
          )}

          {/* Error Messages */}
          <div className="space-y-2 text-sm text-red-500">
            {passwordError.lowercase && (
              <div>Password must contain at least one lowercase letter.</div>
            )}
            {passwordError.uppercase && (
              <div>Password must contain at least one uppercase letter.</div>
            )}
            {passwordError.number && (
              <div>Password must contain at least one number.</div>
            )}
            {passwordError.special && (
              <div>Password must contain at least one special character.</div>
            )}
            {!passwordError.passLength && (
              <div>Password must be at least 11 characters long.</div>
            )}
            {!passwordError.passwordMatch && <div>Passwords must match.</div>}
          </div>

          {/* Error Display */}
          {error && <p className="text-red-500">{error}</p>}
        </div>
      ) : loading ? (
        <SmallSpinner />
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default MagicInfraSignupForgot;
