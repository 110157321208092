import { useEffect, useState } from 'react';
import { adminPost } from '../../utils';
import { URLS } from '../../constants';
import getFormattedDate from './utils';
import Spinner from '../../components/Spinner';
import { Link } from 'react-router-dom';

const ManageOpsSites = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsersLoading(true);
        const response = await adminPost(URLS.GET_OPS_SITES, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch ops sites');
          return;
        }
        const data = await response.json();
        setUsers(data.users);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching ops sites:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [page]);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`px-3 py-1 rounded-lg transition-all border ${
            i === page
              ? 'bg-blue-500 text-white border-blue-500'
              : 'bg-white text-gray-600 border-gray-300 hover:bg-gray-100 hover:text-blue-500'
          }`}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  return (
    <div className="p-6 bg-white shadow-lg rounded-lg">
      <Link
        to="/admin/dashboard"
        className="text-blue-600 hover:text-blue-800 font-medium mb-6 inline-block"
      >
        Back To Dashboard
      </Link>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Manage My Sites
      </h2>
      <hr className="border-t-2 border-gray-300 mb-6" />

      {usersLoading ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : !users.length ? (
        <p className="text-center text-gray-500">
          You are not currently managing any sites.
        </p>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-md rounded-lg border border-gray-300">
              <thead className="bg-gray-100 text-left">
                <tr>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Full Name
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Business Name
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Client Email
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Phone Number
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Address
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Created
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Sales Rep
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Installer
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Ops User
                  </th>
                  <th className="px-4 py-2 font-medium text-gray-600">
                    Client Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id} className="border-t">
                    <td className="px-4 py-2 text-gray-800">{user.fullName}</td>
                    <td className="px-4 py-2 text-gray-800">
                      {user.businessName}
                    </td>
                    <td className="px-4 py-2 text-gray-800">{user.email}</td>
                    <td className="px-4 py-2 text-gray-800">
                      {user.phoneNumber}
                    </td>
                    <td className="px-4 py-2 text-gray-800">
                      {user.streetAddress}, {user.city}, {user.state}{' '}
                      {user.zipCode}
                    </td>
                    <td className="px-4 py-2 text-gray-800">
                      {getFormattedDate(user.created)}
                    </td>
                    <td className="px-4 py-2 text-gray-800">
                      {user.createdBy}
                    </td>
                    <td className="px-4 py-2 text-gray-800">
                      {user.installer}
                    </td>
                    <td className="px-4 py-2 text-gray-800">{user.opsUser}</td>
                    <td className="px-4 py-2 text-gray-800">{user.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center mt-6">
            <button
              onClick={prevPage}
              disabled={page === 1}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 transition duration-300"
            >
              Prev
            </button>
            <div className="flex space-x-2">{renderPageNumbers()}</div>
            <button
              onClick={nextPage}
              disabled={page === totalPages}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 transition duration-300"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageOpsSites;
