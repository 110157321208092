import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';
import { adminPost } from '../../utils';
import { POTENTIAL_CLIENT_STATUS, URLS } from '../../constants';

const PotentialHouseholdClients = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedEmails, setSelectedEmails] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsersLoading(true);
        const response = await adminPost(URLS.GET_POTENTIAL_HOUSEHOLDS, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch potential households');
          return;
        }
        const data = await response.json();
        setUsers(data.users);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching potential households:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [page]);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`px-3 py-1 rounded-lg transition-all border ${
            i === page
              ? 'bg-blue-500 text-white border-blue-500'
              : 'bg-white text-gray-600 border-gray-300 hover:bg-gray-100 hover:text-blue-500'
          }`}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(email)) {
        return prevSelectedEmails.filter(
          (selectedEmail) => selectedEmail !== email,
        );
      } else {
        return [...prevSelectedEmails, email];
      }
    });
  };

  return (
    <div className="admin-dash-container p-6 bg-white rounded-lg shadow-md">
      <Link
        to="/admin/dashboard"
        className="text-blue-600 hover:text-blue-800 font-medium text-lg"
      >
        Back To Dashboard
      </Link>
      <h2 className="text-2xl font-semibold mt-4 mb-2">
        Potential Household Clients
      </h2>
      <hr className="border-gray-300 my-4" />
      <Link
        className="px-4 py-2 mb-4 bg-blue-600 text-white rounded hover:bg-blue-700"
        to="/admin/potential-household-clients/add"
      >
        Add Potential Household
      </Link>

      <hr className="border-gray-300 my-4" />

      {usersLoading ? (
        <Spinner />
      ) : !users.length ? (
        <p className="text-center text-gray-600">No potential households.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto text-sm text-left text-gray-600">
            <thead className="bg-gray-100">
              <tr>
                <th></th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Full Name
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">Email</th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Phone Number
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">Address</th>
                <th className="py-3 px-4 font-medium text-gray-700">City</th>
                <th className="py-3 px-4 font-medium text-gray-700">State</th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Zip Code
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Ownership
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">Created</th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Referred By
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Sales Rep
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">Status</th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Installer
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">
                  Ops Notes
                </th>
                <th className="py-3 px-4 font-medium text-gray-700">Approve</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.email} className="border-b hover:bg-gray-50">
                  <td className="py-3 px-4">
                    <input
                      type="checkbox"
                      disabled={[
                        POTENTIAL_CLIENT_STATUS.OPS_REVIEW,
                        POTENTIAL_CLIENT_STATUS.ACCOUNT_CREATED,
                        POTENTIAL_CLIENT_STATUS.SALES_REJECT,
                        POTENTIAL_CLIENT_STATUS.OPS_REJECT,
                      ].includes(user.status)}
                      onChange={() => handleCheckboxChange(user.email)}
                      checked={selectedEmails.includes(user.email)}
                      className="cursor-pointer"
                    />
                  </td>
                  <td className="py-3 px-4">{user.fullName}</td>
                  <td className="py-3 px-4">{user.email}</td>
                  <td className="py-3 px-4">{user.phoneNumber}</td>
                  <td className="py-3 px-4">{user.streetAddress}</td>
                  <td className="py-3 px-4">{user.city}</td>
                  <td className="py-3 px-4">{user.state}</td>
                  <td className="py-3 px-4">{user.zipCode}</td>
                  <td className="py-3 px-4">{user.ownership}</td>
                  <td className="py-3 px-4">
                    {getFormattedDate(user.created)}
                  </td>
                  <td className="py-3 px-4">{user.referredBy}</td>
                  <td className="py-3 px-4">{user.createdBy}</td>
                  <td className="py-3 px-4">
                    {user.status ?? POTENTIAL_CLIENT_STATUS.INITIALIZED}
                  </td>
                  <td className="py-3 px-4">{user.installer}</td>
                  <td className="py-3 px-4">
                    {user.opsNotes?.map((note, i) => (
                      <div key={i}>
                        {note}
                        <hr className="border-gray-300 my-2" />
                      </div>
                    ))}
                  </td>
                  <td className="py-3 px-4">
                    <button
                      disabled={
                        user.status !== POTENTIAL_CLIENT_STATUS.SALES_REVIEW
                      }
                      className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                      Invite
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-between items-center mt-4">
        <button
          onClick={prevPage}
          disabled={page === 1}
          className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          Prev
        </button>

        <div className="flex items-center space-x-2">{renderPageNumbers()}</div>

        <button
          onClick={nextPage}
          disabled={page === totalPages}
          className="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PotentialHouseholdClients;
