import { useNavigate } from 'react-router-dom';

const jobs = [
  { title: 'Business Development Associate', location: 'New York' },
  { title: 'Business Development Associate', location: 'Miami' },
  { title: 'Business Development Associate', location: 'Las Vegas' },
  { title: 'Business Development Associate', location: 'Oaxaca' },
  { title: 'Business Development Associate', location: 'Your City' },
];

const Careers = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-white p-6 sm:p-8 mx-auto rounded-lg shadow-lg">
      <section id="careers">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Careers
        </h2>
        <div className="text-block px-4 sm:px-6 lg:px-8">
          <div className="block-text space-y-6">
            {jobs.map((job, index) => (
              <div
                className="link-container cursor-pointer border hover:bg-gray-100 rounded-lg p-4 transition-all duration-300"
                onClick={() =>
                  navigate('/jobs/business-development-regional-lead')
                }
                key={index}
              >
                <div className="career-link text-lg font-semibold text-gray-800">
                  {job.title}
                  <br />
                  <span className="text-sm text-green-500">
                    ({job.location})
                  </span>
                  <br />
                  <br />
                </div>
              </div>
            ))}
            <p className="stay-tuned text-gray-600 text-center">
              Stay tuned for more job postings!
            </p>
          </div>
        </div>
      </section>
      <br />
      <br />
    </div>
  );
};

export default Careers;
