import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../constants';
import { clientPost } from '../../../utils';
import moment from 'moment';
import SmallSpinner from '../../../components/SmallSpinner';

const MenuPage = ({ client, setClient }) => {
  const [metadata, setMetadata] = useState({
    earnings: {
      monthlyEarnings: '0.00',
      currentMonth: '',
      siteAmount: 0,
      deviceAmount: 0,
    },
    appointments: {
      upcomingAppointments: 0,
      mostRecentAppointment: null,
    },
    payout: {
      methods: false,
    },
  });

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await clientPost(URLS.GET_CLIENT_MENU);
        if (response.ok) {
          const data = await response.json();
          setMetadata(data);
        } else {
          console.error('Failed to fetch metadata');
        }
      } catch (error) {
        console.error('Error fetching metadata:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
  }, []);

  const getCurrentMonth = () => {
    const currentDate = new Date();

    // Get the current month (returns a zero-based index, so January is 0)
    const currentMonth = currentDate.getMonth();

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const currentMonthName = monthNames[currentMonth];

    return currentMonthName;
  };

  const goToPayout = async () => {
    try {
      const response = await clientPost(URLS.GO_TO_PAYOUT);
      if (response.ok) {
        setClient({ status: 'PAYOUT' });
        navigate('/account');
      } else {
        console.error('Failed to skip payout.');
      }
    } catch (error) {
      console.error('Error skipping payout:', error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold text-blue-800 mb-6">User Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6 bg-white rounded-lg shadow-md">
        <div className="bg-blue-50 p-4 rounded-lg shadow-sm">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-blue-800">
              My Sites and Earnings
            </h2>
            <p className="text-gray-600">
              Earning details for{' '}
              {metadata.earnings.currentMonth ?? getCurrentMonth()} coming soon!
            </p>
          </div>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg shadow-sm">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-blue-800">
              My Appointments
            </h2>
            {loading ? (
              <div className="flex justify-center">
                <SmallSpinner />
              </div>
            ) : (
              <>
                {metadata.appointments.upcomingAppointments ? (
                  <>
                    <p className="text-gray-600">
                      You have {metadata.appointments.upcomingAppointments ?? 0}{' '}
                      upcoming appointment
                      {metadata.appointments.upcomingAppointments !== 1 &&
                        metadata.appointments.upcomingAppointments !== '1' &&
                        's'}
                      .
                    </p>
                    {metadata.appointments.mostRecentAppointment && (
                      <p className="text-gray-600">
                        Your next appointment date is:{' '}
                        {moment
                          .utc(metadata.appointments.mostRecentAppointment)
                          .local()
                          .format('MMMM DD, YYYY h:mm A')}
                      </p>
                    )}
                  </>
                ) : (
                  <p className="text-gray-600">No upcoming appointments.</p>
                )}
              </>
            )}
          </div>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg shadow-sm">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-blue-800">
              Payout Options
            </h2>
            {!metadata.payout.methods ? (
              <div className="flex justify-center">
                <SmallSpinner />
              </div>
            ) : (
              <>
                <div className="space-y-2">
                  {!metadata.payout.methods.length ? (
                    <button
                      className="w-full py-3 px-6 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                      type="button"
                      onClick={goToPayout}
                    >
                      Add payout method
                    </button>
                  ) : (
                    metadata.payout.methods.map((item, key) => (
                      <div key={key} className="payout-method">
                        {item === 'STRIPE' && (
                          <div className="text-gray-600">ACH via Stripe</div>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="col-span-full bg-blue-50 p-4 rounded-lg shadow-sm">
          <p className="text-gray-600">
            For any questions, please contact us at{' '}
            <a
              href={`mailto:info@rhythmwireless.io`}
              className="text-blue-600 hover:underline"
            >
              info@rhythmwireless.io
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default MenuPage;
