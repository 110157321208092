const CLIENT_STATUS = Object.freeze({
  APPOINTMENT: 'APPOINTMENT',
  CONTRACT: 'CONTRACT',
  INVITED: 'INVITED',
  HOME: 'HOME',
  PAYOUT: 'PAYOUT',
  PRE_INVITE: 'PRE_INVITE',
});

const CLIENT_TYPE = Object.freeze({
  BUSINESS: 'BUSINESS',
  HOUSEHOLD: 'HOUSEHOLD',
});

const HOUSEHOLD_TYPE = Object.freeze({
  OWNER: 'OWNER',
  RENTER: 'RENTER',
});

const INFRA_ROLES = Object.freeze(['ADMIN', 'SALES', 'OPS', 'INSTALLER']);

const POTENTIAL_CLIENT_STATUS = Object.freeze({
  CREATED: 'CREATED', // DEPRECATED
  INITIALIZED: 'INITIALIZED',
  OPS_REVIEW: 'OPS_REVIEW',
  SALES_REVIEW: 'SALES_REVIEW',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  OPS_REJECT: 'OPS_REJECT',
  SALES_REJECT: 'SALES_REJECT',
});

const POTENTIAL_SITE_STATUS = Object.freeze({
  REJECTED: 'REJECTED',
  OPS_REVIEW: 'OPS_REVIEW',
  APPROVED: 'APPROVED',
});

const ROLE = Object.freeze({
  ADMIN: 'ADMIN',
  SALES: 'SALES',
  OPS: 'OPS',
  CLIENT: 'CLIENT',
  INSTALLER: 'INSTALLER',
});

const TOKEN_TYPE = Object.freeze({
  INFRA: 'INFRA',
  CLIENT: 'CLIENT',
});

const URLS = Object.freeze({
  SUBMIT_HOUSEHOLD_FORM: 'submit-household-form',
  SUBMIT_BUSINESS_FORM: 'submit-business-form',
  ADMIN_SIGNIN: 'admin/signin',
  INFRA_LOGOUT: 'admin/logout',
  INVITE_INFRA_USER: 'admin/invite-infra-user',
  CREATE_CLIENT: 'client/create-client',
  CREATE_CLIENT_PASSWORD: 'client/create-client-password',
  UPDATE_INFRA_USER: 'admin/update-infra-user',
  VALIDATE_CLIENT_MAGIC_CODE: 'client/validate-client-magic-code',
  VALIDATE_CLIENT_MAGIC_CODE_FORGOT: 'client/validate-client-magic-code-forgot',
  VALIDATE_INFRA_MAGIC_CODE: 'admin/validate-infra-magic-code',
  VALIDATE_INFRA_MAGIC_CODE_FORGOT: 'admin/validate-infra-magic-code-forgot',
  VALIDATE_CLIENT_TOKEN: 'admin/validate-client-token',
  VALIDATE_INFRA_TOKEN: 'admin/validate-infra-token',
  GET_POTENTIAL_HOUSEHOLDS: 'admin/get-potential-households',
  GET_POTENTIAL_BUSINESSES: 'admin/get-potential-businesses',
  ADD_POTENTIAL_HOUSEHOLD: 'admin/add-potential-household',
  ADD_POTENTIAL_BUSINESS: 'admin/add-potential-business',
  SEND_TO_OPS: 'admin/send-to-ops',
  REJECT_POTENTIAL: 'admin/reject-potential',
  UPDATE_AVAILABILITY: 'admin/update-availability',
  GET_AVAILABILITIES: 'admin/get-availabilities',
  GET_READY_FOR_OPS_REVIEW: 'admin/get-ready-for-ops-review',
  GET_OPS_SITES: 'admin/get-ops-sites',
  GET_INSTALLER_SITES: 'admin/get-installer-sites',
  GET_INSTALLERS: 'admin/get-installers',
  SEND_TO_SALES_REVIEW: 'admin/send-to-sales-review',
  SEND_TO_OPS_REJECT: 'admin/send-to-ops-reject',
  INVITE_CLIENT_TO_MAKE_ACCOUNT: 'admin/invite-client-to-make-account',
  GET_ADMIN_USERS: 'admin/get-infra-users',
  UPDATE_POTENTIAL_BUSINESS: 'admin/update-potential-business',
  GET_POTENTIAL_BUSINESSES_COMMENTS: 'admin/get-potential-business-comments',
  ADD_POTENTIAL_BUSINESS_COMMENT: 'admin/add-potential-business-comment',
  GET_CLIENT_DOCUMENTS: 'admin/get-client-documents',
  UPLOAD_CONTRACT: 'admin/upload-contract',
  GET_ACTIVE_CLIENTS: 'admin/get-active-clients',
  CREATE_CLIENT_ACCOUNT: 'admin/create-client-account',
  GET_ACTIVE_CLIENT_DETAILS: 'admin/get-active-client-details',
  SAVE_ONBOARDING_SETTINGS: 'admin/save-onboarding-settings',
  SCHEDULE_APPOINTMENT: 'admin/schedule-appointment',
  ADD_SITE: 'admin/add-site',
  GET_OPS_REVIEW_SITES: 'admin/get-ops-review-sites',
  APPROVE_SITE: 'admin/approve-site',
  REJECT_SITE: 'admin/reject-site',
  ADMIN_FORGOT_PASSWORD: 'admin/forgot-password',

  CLIENT_LOGOUT: 'client/logout',
  CLIENT_LOGIN: 'client/login',
  CLIENT_SIGN_HOSTING: 'client/sign-hosting',
  GET_INSTALLER_METADATA: 'client/get-installer-metadata',
  CLIENT_FINISH_APPOINTMENT: 'client/finish-appointment',
  GET_CLIENT_MENU: 'client/get-menu',
  FETCH_HOST_AGREEMENT: 'document/host-agreement',
  CLIENT_FINISH_PAYOUT: 'client/finish-payout',
  CLIENT_SKIP_PAYOUT: 'client/skip-payout',
  STRIPE_ACCOUNT: 'client/stripe-account',
  STRIPE_ACCOUNT_SESSION: 'client/stripe-account-session',
  GO_TO_PAYOUT: 'client/go-to-payout',
  USER_FORGOT_PASSWORD: 'client/forgot-password',
});

export {
  CLIENT_STATUS,
  CLIENT_TYPE,
  HOUSEHOLD_TYPE,
  INFRA_ROLES,
  POTENTIAL_CLIENT_STATUS,
  POTENTIAL_SITE_STATUS,
  ROLE,
  TOKEN_TYPE,
  URLS,
};
