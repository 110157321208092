import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { adminPost } from '../../utils';
import { CLIENT_TYPE, POTENTIAL_CLIENT_STATUS, URLS } from '../../constants';
import Spinner from '../../components/Spinner';
import getFormattedDate from './utils';
import CustomModal from '../../components/CustomModal';
import EditPotentialBusinessOps from '../../components/EditPotentialBusinessOps';

const ManageOpsPotentialSites = () => {
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [commentsLoading, setCommentsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [allInstallers, setAllInstallers] = useState([]);
  const [updatedUsers, setUpdatedUsers] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsersLoading(true);
        const response = await adminPost(URLS.GET_READY_FOR_OPS_REVIEW, {
          page,
        });
        if (!response.ok) {
          console.error('Failed to fetch potential clients');
          return;
        }
        const data = await response.json();
        const updatedUsersArray = data.users.map((user) => ({
          ...user,
          opsNotes: '',
          installer: '',
        }));
        setUpdatedUsers(updatedUsersArray);
        const usersWithOpsNotes = data.users.map((user) => ({
          ...user,
          opsNotes: user.opsNotes || [],
        }));
        setUsers(usersWithOpsNotes);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching potential clients:', error);
      }
      setUsersLoading(false);
    };
    fetchUsers();
  }, [page]);

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await adminPost(URLS.GET_INSTALLERS);
        if (!response.ok) {
          console.error('Failed to fetch installers');
          return;
        }
        const data = await response.json();
        setAllInstallers(data.installers);
      } catch (error) {
        console.error('Error fetching installers:', error);
      }
    };
    fetchInstallers();
  }, []);

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => goToPage(i)}
          className={`px-3 py-1 rounded-lg transition-all border ${
            i === page
              ? 'bg-blue-500 text-white border-blue-500'
              : 'bg-white text-gray-600 border-gray-300 hover:bg-gray-100 hover:text-blue-500'
          }`}
        >
          {i}
        </button>,
      );
    }
    return pageNumbers;
  };

  const handleApprove = async (
    potentialClientId,
    type,
    opsNotes,
    installer,
    event,
  ) => {
    event.stopPropagation();
    const updated = users.map((user) =>
      user.id === potentialClientId
        ? {
            ...user,
            status: POTENTIAL_CLIENT_STATUS.SALES_REVIEW,
            installer,
          }
        : user,
    );
    setUsers(updated);
    try {
      const response = await adminPost(URLS.SEND_TO_SALES_REVIEW, {
        potentialClientId,
        type,
        opsNotes,
        installer,
      });
      if (!response.ok) {
        console.error('Failed to approve user');
      }
      const updatedUsersArray = updatedUsers.map((user) =>
        user.id === potentialClientId
          ? { ...user, opsNotes: '', installer: '' }
          : user,
      );
      setUpdatedUsers(updatedUsersArray);
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const handleDeny = async (userId, type, opsNotes, event) => {
    event.stopPropagation();
    const updated = users.map((user) =>
      user.id === userId
        ? {
            ...user,
            status: POTENTIAL_CLIENT_STATUS.OPS_REJECT,
          }
        : user,
    );
    setUsers(updated);
    try {
      const response = await adminPost(URLS.SEND_TO_OPS_REJECT, {
        userId,
        type,
        opsNotes,
      });
      if (!response.ok) {
        console.error('Failed to deny user');
      }
      const updatedUsersArray = updatedUsers.map((user) =>
        user.id === userId ? { ...user, opsNotes: '', installer: '' } : user,
      );
      setUpdatedUsers(updatedUsersArray);
    } catch (error) {
      console.error('Error denying user:', error);
    }
  };

  const handleInstallerChange = (userId, installerEmail) => {
    setUpdatedUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, installer: installerEmail } : user,
      ),
    );
  };

  const handleOpsNotesChange = (userId, opsNotes) => {
    setUpdatedUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, opsNotes } : user,
      ),
    );
  };

  const fetchComments = async (potentialBusinessId) => {
    try {
      setCommentsLoading(true);
      const response = await adminPost(URLS.GET_POTENTIAL_BUSINESSES_COMMENTS, {
        potentialBusinessId,
      });
      if (!response.ok) {
        console.error('Failed to fetch potential business comments.');
        return;
      }
      const data = await response.json();
      setComments(data.comments);
    } catch (error) {
      console.error('Error fetching potential businesses comments:', error);
    }
    setCommentsLoading(false);
  };

  const handleRowClick = async (user) => {
    setSelectedUser(user);
    await fetchComments(user.id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleWriteComment = async () => {
    try {
      const response = await adminPost(URLS.ADD_POTENTIAL_BUSINESS_COMMENT, {
        comment,
        potentialClientId: selectedUser.id,
      });
      if (!response.ok) {
        console.error('Error adding comment to potential business.');
        return;
      }
      const newComment = await response.json();
      setComments((prevComments) => [newComment, ...prevComments]);
      setComment('');
    } catch (error) {
      console.error('Error adding comment to potential business: ', error);
    }
  };

  return (
    <div className="p-4">
      <Link to="/admin/dashboard" className="text-blue-500 hover:underline">
        Back To Dashboard
      </Link>
      <h2 className="text-2xl font-bold mt-4">Manage Potential Sites</h2>
      <hr className="my-4" />
      <p className="text-lg">
        *Ideal path for potential clients: <b>INITIALIZED</b> ➡️{' '}
        <b>OPS_REVIEW</b> ➡️ <b>SALES_REVIEW</b> ➡️ <b>ACCOUNT_CREATED</b>
      </p>

      {usersLoading ? (
        <Spinner />
      ) : !users.length ? (
        <p className="text-center text-gray-500">No potential businesses.</p>
      ) : (
        <>
          <div className="overflow-x-auto mt-4">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Actions</th>
                  <th className="px-4 py-2 border-b">Status</th>
                  <th className="px-4 py-2 border-b">Client Name</th>
                  <th className="px-4 py-2 border-b">Business Name</th>
                  <th className="px-4 py-2 border-b">Business Title</th>
                  <th className="px-4 py-2 border-b">Email</th>
                  <th className="px-4 py-2 border-b">Phone Number</th>
                  <th className="px-4 py-2 border-b">Street Address</th>
                  <th className="px-4 py-2 border-b">City</th>
                  <th className="px-4 py-2 border-b">State</th>
                  <th className="px-4 py-2 border-b">Zip Code</th>
                  <th className="px-4 py-2 border-b">Daily Patrons</th>
                  <th className="px-4 py-2 border-b">Square Footage</th>
                  <th className="px-4 py-2 border-b">Created</th>
                  <th className="px-4 py-2 border-b">Referred By</th>
                  <th className="px-4 py-2 border-b">Sales Rep</th>
                  <th className="px-4 py-2 border-b">Installer</th>
                  <th className="px-4 py-2 border-b">Ops User</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr
                    key={user.id}
                    className="cursor-pointer hover:bg-gray-100"
                    onClick={() => handleRowClick(user)}
                  >
                    <td className="px-4 py-2 border-b flex items-center space-x-2">
                      <button
                        onClick={(event) =>
                          handleApprove(
                            user.id,
                            user.type,
                            updatedUsers[index].opsNotes,
                            updatedUsers[index].installer,
                            event,
                          )
                        }
                        disabled={
                          !updatedUsers[index].installer ||
                          (user.status !== POTENTIAL_CLIENT_STATUS.OPS_REVIEW &&
                            user.status !==
                              POTENTIAL_CLIENT_STATUS.SALES_REVIEW)
                        }
                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 text-sm disabled:opacity-50"
                      >
                        Approve
                      </button>
                      <button
                        onClick={(event) =>
                          handleDeny(
                            user.id,
                            user.type,
                            updatedUsers[index].opsNotes,
                            event,
                          )
                        }
                        disabled={
                          user.status ===
                            POTENTIAL_CLIENT_STATUS.ACCOUNT_CREATED ||
                          user.status ===
                            POTENTIAL_CLIENT_STATUS.SALES_REJECT ||
                          user.status === POTENTIAL_CLIENT_STATUS.OPS_REJECT
                        }
                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 text-sm disabled:opacity-50"
                      >
                        Deny
                      </button>
                      <div className="flex items-center space-x-2">
                        <select
                          onClick={(event) => event.stopPropagation()}
                          onChange={(e) =>
                            handleInstallerChange(user.id, e.target.value)
                          }
                          value={updatedUsers[index].installer}
                          disabled={
                            user.status !==
                              POTENTIAL_CLIENT_STATUS.OPS_REVIEW &&
                            user.status !== POTENTIAL_CLIENT_STATUS.SALES_REVIEW
                          }
                          className="p-1.5 border rounded-md text-sm"
                        >
                          <option value="">Select Installer</option>
                          {allInstallers.map((installer) => (
                            <option
                              key={installer.email}
                              value={installer.email}
                            >
                              {installer.email}
                            </option>
                          ))}
                        </select>
                        <textarea
                          onClick={(event) => event.stopPropagation()}
                          placeholder="Add Ops Notes"
                          value={updatedUsers[index].opsNotes}
                          onChange={(e) =>
                            handleOpsNotesChange(user.id, e.target.value)
                          }
                          disabled={
                            user.status ===
                              POTENTIAL_CLIENT_STATUS.ACCOUNT_CREATED ||
                            user.status ===
                              POTENTIAL_CLIENT_STATUS.SALES_REJECT ||
                            user.status === POTENTIAL_CLIENT_STATUS.OPS_REJECT
                          }
                          className="p-1.5 border rounded-md text-sm w-36"
                        />
                      </div>
                    </td>

                    <td className="px-4 py-2 border-b">{user.status}</td>
                    <td className="px-4 py-2 border-b">
                      {user.type === CLIENT_TYPE.BUSINESS
                        ? user.contactTitle
                        : user.fullName}
                    </td>
                    <td className="px-4 py-2 border-b">{user.businessName}</td>
                    <td className="px-4 py-2 border-b">{user.title}</td>
                    <td className="px-4 py-2 border-b">{user.email}</td>
                    <td className="px-4 py-2 border-b">{user.phoneNumber}</td>
                    <td className="px-4 py-2 border-b">
                      {user.type === CLIENT_TYPE.BUSINESS
                        ? user.address
                        : user.streetAddress}
                    </td>
                    <td className="px-4 py-2 border-b">{user.city}</td>
                    <td className="px-4 py-2 border-b">{user.state}</td>
                    <td className="px-4 py-2 border-b">{user.zipCode}</td>
                    <td className="px-4 py-2 border-b">{user.dailyPatrons}</td>
                    <td className="px-4 py-2 border-b">{user.squareFootage}</td>
                    <td className="px-4 py-2 border-b">
                      {getFormattedDate(user.created)}
                    </td>
                    <td className="px-4 py-2 border-b">{user.referredBy}</td>
                    <td className="px-4 py-2 border-b">{user.createdBy}</td>
                    <td className="px-4 py-2 border-b">{user.installer}</td>
                    <td className="px-4 py-2 border-b">{user.opsUser}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center mt-6">
            <button
              onClick={prevPage}
              disabled={page === 1}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-300"
            >
              Prev
            </button>
            <div className="flex space-x-2">{renderPageNumbers()}</div>
            <button
              onClick={nextPage}
              disabled={page === totalPages}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-300"
            >
              Next
            </button>
          </div>
        </>
      )}
      {isModalOpen && (
        <CustomModal onClose={handleCloseModal}>
          <EditPotentialBusinessOps
            selectedUser={selectedUser}
            comment={comment}
            setComment={setComment}
            handleWriteComment={handleWriteComment}
            commentsLoading={commentsLoading}
            comments={comments}
            handleCloseModal={handleCloseModal}
          />
        </CustomModal>
      )}
    </div>
  );
};

export default ManageOpsPotentialSites;
