import { useState } from 'react';
import { Link } from 'react-router-dom';
import { post } from '../../utils';
import { URLS } from '../../constants';
import SmallSpinner from '../../components/SmallSpinner';

const AdminForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const response = await post(URLS.ADMIN_FORGOT_PASSWORD, { email });

      if (response.ok) {
        setMessage(
          'Your password reset link has been emailed to you. You have 15 minutes to change your password.',
        );
        setSubmitting(false);
        setSubmitted(true);
      } else {
        setMessage('Failed to send password reset link. Please try again.');
        setSubmitting(false);
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
      setSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center bg-gray-50 p-6">
      <form
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md space-y-6"
        onSubmit={handleSubmit}
      >
        <h2 className="text-3xl font-semibold text-gray-800">
          Admin Forgot Password
        </h2>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Enter email:
          </label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mt-2"
          />
        </div>

        {submitting ? (
          <div className="flex justify-center">
            <SmallSpinner />
          </div>
        ) : (
          <button
            type="submit"
            disabled={submitted}
            className="w-full py-3 bg-blue-600 text-white font-semibold rounded-md disabled:bg-gray-300 hover:bg-blue-700 focus:outline-none mt-4"
          >
            Submit
          </button>
        )}

        {message && (
          <p className="text-center text-sm text-gray-700 mt-4">{message}</p>
        )}

        <Link
          to="/admin"
          className="block text-center text-blue-600 hover:text-blue-800 text-sm mt-4"
        >
          Back to admin login.
        </Link>
      </form>
    </div>
  );
};

export default AdminForgotPassword;
