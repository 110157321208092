import { ROLE } from '../constants';
import Spinner from './Spinner';
import getFormattedDate from '../pages/admin/utils';

const EditPotentialBusiness = ({
  selectedUser,
  handleSaveChanges,
  setSelectedUser,
  allInstallers,
  comment,
  setComment,
  comments,
  commentsLoading,
  handleCloseModal,
  handleWriteComment,
}) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-3xl mx-auto">
      <h2 className="text-xl font-bold text-gray-800 mb-4">Edit User</h2>
      {selectedUser && (
        <form
          onSubmit={() => handleSaveChanges(selectedUser)}
          className="space-y-4"
        >
          <p className="text-sm text-gray-600">
            <span className="font-medium">Client Email:</span>{' '}
            {selectedUser.email}
          </p>

          {/* Contact Title */}
          <div>
            <label
              htmlFor="contactTitle"
              className="block text-sm font-medium text-gray-700"
            >
              Contact Title
            </label>
            <input
              type="text"
              id="contactTitle"
              value={selectedUser.contactTitle}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  contactTitle: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          {/* Business Details */}
          <div>
            <label
              htmlFor="businessName"
              className="block text-sm font-medium text-gray-700"
            >
              Business Name
            </label>
            <input
              type="text"
              id="businessName"
              value={selectedUser.businessName}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  businessName: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          {/* Business Title */}
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Business Title
            </label>
            <input
              type="text"
              id="title"
              value={selectedUser.title}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  title: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          {/* Phone Number */}
          <div>
            <label
              htmlFor="phoneNumber"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <input
              type="text"
              id="phoneNumber"
              value={selectedUser.phoneNumber}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  phoneNumber: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="dailyPatrons"
              className="block text-sm font-medium text-gray-700"
            >
              Daily Patrons
            </label>
            <input
              type="text"
              id="dailyPatrons"
              value={selectedUser.dailyPatrons}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  dailyPatrons: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="squareFootage"
              className="block text-sm font-medium text-gray-700"
            >
              Square Footage
            </label>
            <input
              type="text"
              id="squareFootage"
              value={selectedUser.squareFootage}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  squareFootage: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="referredBy"
              className="block text-sm font-medium text-gray-700"
            >
              Referred By
            </label>
            <input
              type="text"
              id="referredBy"
              value={selectedUser.referredBy}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  referredBy: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="createdBy"
              className="block text-sm font-medium text-gray-700"
            >
              Sales Rep
            </label>
            <input
              type="text"
              id="createdBy"
              value={selectedUser.createdBy}
              disabled
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="cameFromWebsite"
              className="block text-sm font-medium text-gray-700"
            >
              Came From Website
            </label>
            <input
              type="text"
              id="cameFromWebsite"
              value={selectedUser.cameFromWebsite}
              disabled
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Street
            </label>
            <input
              type="text"
              id="address"
              value={selectedUser.address}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  address: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="city"
              className="block text-sm font-medium text-gray-700"
            >
              City
            </label>
            <input
              type="text"
              id="city"
              value={selectedUser.city}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  city: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="state"
              className="block text-sm font-medium text-gray-700"
            >
              State
            </label>
            <input
              type="text"
              id="state"
              value={selectedUser.state}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  state: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="zipCode"
              className="block text-sm font-medium text-gray-700"
            >
              Zip Code
            </label>
            <input
              type="text"
              id="zipCode"
              value={selectedUser.zipCode}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  zipCode: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div className="border p-1">
            <label htmlFor="skipOpsReview" className="custom-form-1-label">
              Skip Review by Ops Team (Optional):
              <br />
              <i>Note: Must select Ops User and Installer</i>
            </label>
            <input
              type="checkbox"
              id="skipOpsReview"
              className="custom-form-1-checkbox"
              checked={selectedUser.skipOpsReview}
              onChange={() =>
                setSelectedUser({
                  ...selectedUser,
                  skipOpsReview: !selectedUser.skipOpsReview, // Toggle the skipOpsReview state
                })
              }
              disabled={!selectedUser.opsUser || !selectedUser.installer} // Disable checkbox if conditions aren't met
            />
          </div>

          {/* Ops User */}
          <div>
            <label
              htmlFor="opsUser"
              className="block text-sm font-medium text-gray-700"
            >
              Ops User
            </label>
            <select
              id="opsUser"
              value={selectedUser.opsUser}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  opsUser: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="">Select Ops User</option>
              {allInstallers
                .filter((installer) =>
                  [ROLE.OPS, ROLE.SALES].includes(installer.role),
                )
                .map((installer) => (
                  <option key={installer.email} value={installer.email}>
                    {installer.email}
                  </option>
                ))}
            </select>
          </div>

          {/* Installer */}
          <div>
            <label
              htmlFor="installer"
              className="block text-sm font-medium text-gray-700"
            >
              Installer
            </label>
            <select
              id="installer"
              value={selectedUser.installer}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  installer: e.target.value,
                })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="">Select Installer</option>
              {allInstallers.map((installer) => (
                <option key={installer.email} value={installer.email}>
                  {installer.email}
                </option>
              ))}
            </select>
          </div>

          {/* Comments */}
          <div>
            <label
              htmlFor="comments"
              className="block text-sm font-medium text-gray-700"
            >
              Comments
            </label>
            <textarea
              id="comments"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add comments here."
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
            <button
              type="button"
              disabled={!comment.length}
              onClick={handleWriteComment}
              className="mt-2 bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-700 disabled:bg-gray-300"
            >
              Comment
            </button>
          </div>

          {/* Comments Display */}
          <div>
            {commentsLoading ? (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              comments.map((comment) => (
                <div
                  key={comment.id}
                  className="p-4 bg-gray-100 rounded-md mt-2 shadow-sm"
                >
                  <p className="text-gray-800">{comment.value}</p>
                  <small className="block text-gray-500">
                    {getFormattedDate(comment.created)}
                  </small>
                  <small className="block text-gray-500">
                    Written by {comment.author}, Role: {comment.type}
                  </small>
                </div>
              ))
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex space-x-4">
            <button
              type="submit"
              disabled={
                selectedUser.skipOpsReview &&
                (!selectedUser.opsUser || !selectedUser.installer)
              }
              className="bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-700 disabled:bg-gray-300"
            >
              Save Changes
            </button>
            <button
              type="button"
              onClick={handleCloseModal}
              className="bg-gray-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-gray-700"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditPotentialBusiness;
