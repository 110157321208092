import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import NotFound from '../../components/NotFound';

import { post } from '../../utils';
import { ROLE, URLS } from '../../constants';
import SmallSpinner from '../../components/SmallSpinner';

const UserResetPassword = ({ login }) => {
  const { magic_code } = useParams();
  const navigate = useNavigate();
  const [validMagicCode, setValidMagicCode] = useState(false);
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [passwordError, setPasswordError] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    special: false,
    passLength: false,
    passwordMatch: false,
  });
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [type, setType] = useState('');

  useEffect(() => {
    const errors = {
      lowercase: !/(?=.*[a-z])/.test(password),
      uppercase: !/(?=.*[A-Z])/.test(password),
      number: !/(?=.*[0-9])/.test(password),
      special: !/(?=.*[!@#$%^&*()_+{}[\]:;<>,./?|-])/.test(password),
      passLength: password.length >= 11,
      passwordMatch:
        password === repeatPassword && (password || repeatPassword),
    };
    setPasswordError(errors);
  }, [password, repeatPassword]);

  useEffect(() => {
    const validateMagicCode = async () => {
      try {
        const response = await post(URLS.VALIDATE_CLIENT_MAGIC_CODE_FORGOT, {
          magic_code,
        });
        if (!response.ok) {
          setError('Magic code validation failed.');
          setLoading(false);
        } else {
          const data = await response.json();
          setEmail(data.email);
          setFullName(data.fullName);
          setType(data.type);
          setValidMagicCode(true);
          setLoading(false);
        }
      } catch (error) {
        setError('Invalid magic code.');
        setLoading(false);
      }
    };

    validateMagicCode();
  }, [magic_code]);

  const handleSignup = async () => {
    setSubmitting(true);
    try {
      const response = await post(URLS.CREATE_CLIENT_PASSWORD, {
        email,
        password,
      });
      if (!response.ok) {
        setError('Resetting password failed.');
        setSubmitting(false);
      } else {
        const data = await response.json();
        Cookies.set('clientToken', data.clientToken);
        Cookies.set('clientEmail', email);
        const user = {
          email,
          token: data.clientToken,
          role: ROLE.CLIENT,
          status: data.status,
          type,
          fullName,
          installer: data.installer,
        };
        login(user);
        navigate('/account');
      }
    } catch (error) {
      console.error('Error signing up:', error);
      setError(error.message);
      setSubmitting(false);
    }
  };

  const handleShowHideButton = (event) => {
    event.preventDefault();
    setHiddenPassword(!hiddenPassword);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSignup();
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg">
      {validMagicCode ? (
        <div className="space-y-6">
          <p className="text-sm text-gray-700">
            Hello <strong>{fullName}</strong>,
            <br />
            Welcome to Rhythm Wireless!
            <br /> Reset your password for{' '}
            <span className="font-semibold">{email}</span>.
          </p>

          <form className="space-y-4">
            <input readOnly hidden autoComplete="username" value="" />

            {/* Password Input */}
            <div className="relative">
              <input
                type={hiddenPassword ? 'password' : 'text'}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                maxLength={40}
                autoComplete="new-password"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="button"
                onClick={handleShowHideButton}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-600 text-xs font-medium py-1 px-2 bg-gray-100 rounded-full border border-gray-300 hover:bg-gray-200 focus:outline-none"
              >
                {hiddenPassword ? 'Show' : 'Hide'}
              </button>
            </div>

            {/* Repeat Password Input */}
            <input
              type="password"
              placeholder="Repeat Password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              maxLength={40}
              autoComplete="new-password"
              onKeyDown={handleKeyDown}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />

            {/* Submit Button */}
            {submitting ? (
              <div className="flex justify-center">
                <SmallSpinner />
              </div>
            ) : (
              <button
                type="button"
                onClick={handleSignup}
                disabled={
                  !password ||
                  !repeatPassword ||
                  passwordError.special ||
                  passwordError.number ||
                  passwordError.uppercase ||
                  passwordError.lowercase ||
                  !passwordError.passLength ||
                  !passwordError.passwordMatch
                }
                className="w-full py-3 px-6 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 disabled:bg-gray-400 transition-colors"
              >
                Reset Password
              </button>
            )}
          </form>

          {/* Password Error Messages */}
          <div className="space-y-2 text-sm text-red-600">
            {passwordError.lowercase && (
              <p>Password must contain at least one lowercase letter.</p>
            )}
            {passwordError.uppercase && (
              <p>Password must contain at least one uppercase letter.</p>
            )}
            {passwordError.number && (
              <p>Password must contain at least one number.</p>
            )}
            {passwordError.special && (
              <p>Password must contain at least one special character.</p>
            )}
            {!passwordError.passLength && (
              <p>Password must be at least 11 characters long.</p>
            )}
            {!passwordError.passwordMatch && <p>Passwords must match.</p>}
          </div>

          {/* Error Display */}
          {error && <p className="text-red-600 text-sm">{error}</p>}
        </div>
      ) : loading ? (
        <div className="flex justify-center">
          <SmallSpinner />
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
};

export default UserResetPassword;
